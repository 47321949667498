import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>
          iHunar formerly iSkillers - IT Company and Training Academy
        </title>
        <meta
          name="description"
          content="Leading software company and training academy, empowering individuals and businesses with cutting-edge technologies and skills."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="og:title"
          property="og:title"
          content="iHunar - IT Company and Training Academy"
        ></meta>
        <meta
          name="twitter:card"
          content="Leading software company and training academy, empowering individuals and businesses with cutting-edge technologies and skills."
        ></meta>
        <link rel="canonical" href="https://rewy-react.envytheme.com/"></link>
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg"
        />
      </Helmet>
    </div>
  )
}

export default SEO
