import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"
import PSEB from "../../assets/images/PSEB.png"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>
              iHunar is the software and IT solution providing Company in
                Pakistan. We provide top notch Software Solutions
                combined with advanced learning through the iHunar Training
                Academy.
              </p>

              <ul className="social-link">
                <li>
                  <Link
                    to="https://www.facebook.com/ihunar.pk"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://twitter.com/ihunarpk"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/iskillerspk/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/ihunarpk/"
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bx bxl-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Member of</h3>
              <img src={PSEB} />
            </div>
          </div> */}
          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Pages</h3>

              <ul className="footer-links-list">
              <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/team">Our Team</Link>
                </li>
                <li>
                  <Link to="/internship">Internships</Link>
                </li>
                <li>
                  <Link to="/jobs">Jobs</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>

              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Contact us</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  1st Floor Kallar House, North Colony, Mithi, <br /> Pakistan
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+923322511711">+92-33-22511711</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:info@ihunar.com">info@ihunar.com</a>
                </li>
                <li>
                  <i className="bx bx-time-five"></i>
                  Opening Hours: 09:00 - 17:00
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>© 2023 All Rights Reserved. iHunar Private Limited</p>
            </div>

            {/* <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms & Conditions</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  )
}

export default Footer
